.Cards1{
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
}
.Cards2{
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
}
.Cards3{
    padding-left: 10px;
    padding-top: 0px;
    padding-right: 10px;
}
