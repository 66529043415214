#form-file-upload {
    height: 10rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: right;
    box-align:center;
  }
  #file-upload {
    display: flex; 
    justify-content: center;
     align-items: center;

    /* display: inline-block;
        padding: 34px 12px;
        cursor: pointer;
        background-color: #2196F3;
        color: #fff;
        border-radius: 4px;
        text-align: center; center align the text */
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
  }
    
    #input-file-upload {
      display: none;
    }
    
    #label-file-upload {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 2px;
      border-radius: 1rem;
      border-style: dashed;
      border-color: #cbd5e1;
      background-color: #f8fafc;
    }
    
    #label-file-upload.drag-active {
      background-color: #ffffff;
    }
    
    .upload-button {
      cursor: pointer;
      padding: 0.25rem;
      font-size: 1rem;
      border: none;
      font-family: 'Oswald', sans-serif;
      background-color: transparent;
    }
    
    .upload-button:hover {
      text-decoration-line: underline;
    }
    
    #drag-file-element {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
    
    .loading-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid #fff;
      border-top-color: #ccc;
      animation: spin 1s ease-in-out infinite;
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }