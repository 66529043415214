
.select_ord{
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 16px;
    width: 100%;
    border: none;
    border-radius: 4px;
    /* background-color: #f1f1f1; */
    cursor: pointer;
  }
  
  /* Hide the default arrow inside the select element */
  .select_ord::-ms-expand {
    display: none;
  }
  
  /* Style the options inside the select element */
  .select_ord option {
    font-weight: normal;
    background-color: #fff;
    color: #000;
  }
  
  /* Style the selected option */
  .select_ord option:checked {
    font-weight: bold;
    background-color: #f1f1f1;
    color: #000;
  }
  
  /* Style the select element when it's opened */
  .select_ord:active,
  .select_ord:focus {
    outline: none;
    box-shadow: none;
  }
  
  /* Style for the filter button */
  .filter-button {
    
    /* width: 1250px;
    background-color: #fff;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 10px 15px;
    background-color:lightblue;
    border: 1px solid #ddd;
    border-radius: 3px;
    text-align: left; */
  
    width:92%;
      background-color: transparent;
      color:#2196f3;
      font-size: larger;
      border: #2196f3;
      border-top: 2px solid #2196f3;
      /* border-bottom: 2px solid #2196f3; */
      padding: 10px 20px;
      text-align: left;
  }
  
  /* Style for the arrow icon */
  .filter-button::after {
    content: "\25BC";
    
    position: absolute;
      top: 23%;
      right: 70px; 
    transform: translateY(-50%);
  }
  /* table {
    box-shadow:2px 2px 7px rgba(0, 0, 0, 0.3); 
     } */
    .lineb{
      width:92%;
      border:none;
      border-top : 3px solid #2196f3;
     }
     .line-button {
      width:92%;
      background-color: transparent;
      font-size: larger;
      color: #2196f3;
      border: none;
      border-top: 3px solid #2196f3;
      padding: 10px 20px;
      text-align: left;
    }
    .line-button::after {
      content: "\25B2";
      position: absolute;
      top: 23%;
      right: 70px; 
      
      transform: translateY(-50%);
    }
   /* Style the form container */
  .form-container {
  max-width: 500px;
  margin: 0 auto;
  }
  
  /* Style the form group */
  .form-group {
  margin-bottom: 10px;
  }
  
  /* Style the labels */
  label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  }
  
  /* Style the input fields */
  input[type=date] {
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font-size: 16px;
  color: #333;
  outline: none;
  }
  
  /* Style the calendar icon */
  input[type=date]::-webkit-calendar-picker-indicator {
  color: #666;
  font-size: 16px;
  }
  
  /* Style the selected date */
  input[type=date]::-webkit-datetime-edit-month-field,
  input[type=date]::-webkit-datetime-edit-day-field,
  input[type=date]::-webkit-datetime-edit-year-field {
  color: #333;
  font-size: 16px;
  }
  
  .movv {
    display: flex;
    justify-content: flex-end;
    margin-right:150px;
    margin-top: 20px;
  }
  
  