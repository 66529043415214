input[type=text],input[type=email],input[type=tel],input[type=number],input[type=submit],
input[type=file],input[type=tel],.sbut{
    width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  /*background-color:#eeeefd;*/
  box-sizing: border-box;
  /*border: 2px solid #d9d9fc;*/
  
}
/*.drop{
  width: 100%;
  border: 2px solid #d9d9fc;
  background-color:#eeeefd;
  border-radius: 2px;
}*/
.sbut{
  background-color:#008CBA;
  border:#008CBA;
  color:white;
  width:40%;
}
.sbut:hover{
  background-color:#12637e;
  color:white;
  width:40%;
}
  ::-webkit-file-upload-button {
    background:#eeeefd;
    color: black;
    
    border: 2px solid #d9d9fc;
    padding: 0.5em;
  }
  .sec{
    background:white;
  }
  

  .label{

    color: red;
    text-align: left;
  }

  .sbut{
    width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  /*background-color:#eeeefd;*/
  box-sizing: border-box;
  /*border: 2px solid #d9d9fc;*/
  
}
/*.drop{
  width: 100%;
  border: 2px solid #d9d9fc;
  background-color:#eeeefd;
  border-radius: 2px;
}*/
.sbut{
  background-color:#008CBA;
  border:#008CBA;
  color:white;
  width:40%;
}
.sbut:hover{
  background-color:#12637e;
  color:white;
  width:40%;
}
  ::-webkit-file-upload-button {
    background:#eeeefd;
    color: black;
    
    border: 2px solid #d9d9fc;
    padding: 0.5em;
  }
  .sec{
    background:white;
  }
  

  .label{

    color: red;
    text-align: left;
  }

 