 
  
  .form-group {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .label1 {
    /* font-weight: bold; */
    margin-bottom: 0.5rem;
  }
  
  .input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .status_select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
    appearance: none;
  }
  
  .status_select option {
    padding: 0.5rem;
  } 
a:hover{
  cursor:pointer;
}