/* .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: lightblue;
  }
  
  .navbar__link {
    cursor: pointer;
    text-decoration: none;
    color: #181818;
  }
  
  .navbar__link:hover {
    transform: translateY(-2px);
    color: white;
  }
  
  .active {
    border-bottom:  solid red;
    color: red;
    transition: border-bottom .5s ease-in-out;
  } */


  @import url('https://fonts.googleapis.com/css2?family=Lato&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@500&family=Roboto+Condensed&display=swap');



  .Nav{
    height: 60px;
    background-color: lightblue;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    
  }
.drop{
    background-color: lightblue;
    color: #2656D8;
}
  .nav_style{
    text-decoration: none;
    color: #2656D8;
  }
  .nav_style:hover{
    color: white;
    transform: translateY(-2px);
  }
  /* .active{
    background-color: red;
  } */
  .button{
    border-radius: 10px;
    color: white;
  }
  .button:hover{
    background-color: b
    lack;
    transition: 0ms;
  }
  .Nav {
    height: 60px;
    padding: 10px; /* Add some padding */
    background-color: lightblue;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
  }